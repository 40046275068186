import React from 'react';
import PropTypes from 'prop-types';
import '../Css/AlertPopups.css';

const PopupOverlay = ({ children, onCancel }) => (
  <div className="popup-overlay" onClick={onCancel}>
    <div className="popup-content" onClick={e => e.stopPropagation()}>
      {children}
    </div>
  </div>
);

export function ConfirmBackSafePopup({ isAlertSent, onConfirm, onCancel }) {
  return (
    <PopupOverlay onCancel={onCancel}>
      <h2>Confirm</h2>
      <p style={{textAlign:'left'}}>
        By confirming, you will be marking this alert as <b>Back & Safe.</b>
        {isAlertSent && (
          <>
            <br />
            <br />
           <span style={{color:'red'}}>An alert was already triggered, please personally contact your Emergency Contacts and let them know you are safe.</span> 
          </>
        )}:
        {!isAlertSent && (
          <>
          <br />
          <br />
         <span>Alert will be deactivated and your contacts will NOT be alerted anymore for this trip.</span> 
        </>
        )}
      </p>
      <button className="btn btn-success" onClick={onConfirm}>
        YES, MARK AS BACK & SAFE
      </button>
      <button className="btn btn-secondary" onClick={onCancel}>
        CANCEL
      </button>
    </PopupOverlay>
  );
}

export function DeleteAlertPopup({ onConfirm, onCancel }) {
  return (
    <PopupOverlay onCancel={onCancel}>
      <h2>Delete alert</h2>
      <p>Are you sure? This cannot be reversed.</p>
      <button className="btn btn-danger" onClick={onConfirm}>
        YES, DELETE THIS ALERT
      </button>
      <button className="btn btn-secondary" onClick={onCancel}>
        CANCEL
      </button>
    </PopupOverlay>
  );
}

ConfirmBackSafePopup.propTypes = {
  isAlertSent: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeleteAlertPopup.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};