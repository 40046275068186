//Login Page
import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { getFirestore, collection, addDoc, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function LoginSignUp() {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const [cookies, setCookie] = useCookies(['userId']);
  const db = getFirestore(app);
  const [userId, setUserId] = useState('');

  const capitalizeWords = (str) => {
    if (!str) return '';
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  useEffect(() => {
    const userIdCookie = cookies.userId;
    const localUserId = localStorage.getItem('userId');
    if (userIdCookie) {
      setUserId(userIdCookie);
      navigate('/alerts');
    } else if (localUserId) {
      setUserId(localUserId);
      setCookie('userId', localUserId, { path: '/' });
      navigate('/alerts');
    }
    setLoading(false);
  }, [cookies, setCookie, navigate]);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    
    if (!email || !password) {
      setErrorMessage("Please fill in all required fields");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long");
      return;
    }

    if (isSignUp && password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        
        // Send email verification
        await sendEmailVerification(user);
        
        // Create user document in Firestore
        await setDoc(doc(db, "UserTable", user.uid), {
          Email: email,
          isProfileComplete: false
        });

        setSuccessMessage("Please check your email to verify your account");
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (!user.emailVerified) {
          setErrorMessage("Please verify your email before logging in");
          return;
        }

        localStorage.setItem('userId', user.uid);
        setCookie('userId', user.uid, { path: '/' });
        navigate('/alerts');
      }
    } catch (error) {
      setErrorMessage(error.message.replace('Firebase: ', ''));
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage("Please enter your email address");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setErrorMessage(error.message.replace('Firebase: ', ''));
    }
  };

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userId = user.uid;
      const userName = user.displayName;
      const userEmail = user.email;
      const formattedName = capitalizeWords(userName);
      
      if (userId) {
        localStorage.setItem('userId', userId);
        setCookie('userId', userId, { path: '/' });
        
        const docRef = doc(db, "UserTable", userId);
        const docSnap = await getDoc(docRef);
        
        if (!docSnap.exists()) {
          await setDoc(doc(db, "UserTable", userId), {
            FullName: formattedName,
            Email: userEmail,
            isProfileComplete: false
          });
        } else {
          const userData = docSnap.data();
          if (userData.isProfileComplete === null || userData.isProfileComplete === undefined) {
            navigate('../ProfileForm');
            return;
          }
        }
        navigate('../alerts');
      }
    } catch (error) {
      setErrorMessage(error.message.replace('Firebase: ', ''));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', backgroundColor: '#f0f1f3' }}>
      <div className="container-fluid d-flex flex-column" style={{ maxWidth: '480px', height: '100%', overflowY: loading ? 'hidden' : 'auto' }}>
        <main className="row flex-grow-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-4" style={{
              fontSize: '2rem',
              fontWeight: 'bolder',
              fontStyle: 'italic',
              textAlign: 'center',
              fontFamily: 'Arial, sans-serif'
            }}>
              alerts
            </h1>

            {errorMessage && (
              <div className="alert alert-danger" role="alert" style={{ width: '80%' }}>
                {errorMessage}
              </div>
            )}

            {successMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '80%' }}>
                {successMessage}
              </div>
            )}

            <form onSubmit={handleEmailSignUp} style={{ width: '80%' }}>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {isSignUp && (
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              )}
              <button 
                type="submit" 
                className="btn btn-lg text-uppercase fw-bold w-100 mb-3"
                style={{ 
                  backgroundColor: '#111827', 
                  borderColor: '#0000FF',
                  color: '#ffffff',
                  fontSize: '1rem',
                  padding: '0.75rem 1.25rem',
                  borderRadius: '0.5rem',
                  fontFamily: 'Arial, sans-serif'
                }}
              >
                {isSignUp ? 'Sign Up' : 'Login'}
              </button>
            </form>

            {!isSignUp && (
              <button
                onClick={handleForgotPassword}
                className="btn btn-link mb-3"
                style={{ textDecoration: 'none' }}
              >
                Forgot Password?
              </button>
            )}

            <div className="mb-3">
              <span style={{ cursor: 'pointer', color: '#0d6efd' }} onClick={() => setIsSignUp(!isSignUp)}>
                {isSignUp ? 'Already have an account? Login' : "Don't have an account? Sign Up"}
              </span>
            </div>

            <div className="d-flex align-items-center mb-3" style={{ width: '80%' }}>
              <hr style={{ flex: 1 }} />
              <span className="mx-3">or</span>
              <hr style={{ flex: 1 }} />
            </div>

            <button
              className="btn btn-lg mb-3"
              onClick={signInWithGoogle}
              style={{
                backgroundColor: '#FFF',
                borderColor: '#black',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80%',
                padding: '10px',
                border: '1px solid black',
                fontFamily: 'Arial, sans-serif'
              }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                alt="Google Logo"
                style={{ marginRight: '10px', width: '24px', height: '24px' }}
              />
              Continue with Google
            </button>

            <div className="row text-center mt-1">
              <div className="col">
                <div className="mt-3">
                  <a href="https://docs.google.com/document/d/1--85XVU2S2VdyUKD_FS9QVxJAcVJyWvWcLNy_bWp8MU/pub" target="_blank" className="text-muted me-3">Terms and Conditions</a>
                  <a href="https://docs.google.com/document/d/1WH0-7Bj-9VlO-3raIKzB1L3m3aG0kxbGJmyhmCBCv3Q/pub" target="_blank" className="text-muted">Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(8px)',
          zIndex: 9999,
        }}>
          <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
        </div>
      )}
    </div>
  );
}

export default LoginSignUp;
