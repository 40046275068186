import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, orderBy, query } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Copy } from 'lucide-react';
import "../Css/dashboard.css";

// Firebase configuration (unchanged)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function App() {
  const [usersCount, setUsersCount] = useState(0);
  const [alertsCount, setAlertsCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [completeProfileUsers, setCompleteProfileUsers] = useState([]);
  const [incompleteProfileUsers, setIncompleteProfileUsers] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [userIdFilter, setUserIdFilter] = useState("");
  const [alertIdFilter, setAlertIdFilter] = useState("");
  const [activeTab, setActiveTab] = useState("completeUsers");
  const [loading, setLoading] = useState(true);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const usersCollection = collection(db, "UserTable");
        const alertsCollection = collection(db, "AlertTable");

        const userQuery = query(usersCollection);
        const alertsQuery = query(alertsCollection, orderBy("CreatedOn", "desc"));
        
        const [usersSnapshot, alertsSnapshot] = await Promise.all([
          getDocs(userQuery),
          getDocs(alertsQuery)
        ]);


        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          Email: doc.data().Email || '',
          FullName: doc.data().FullName || '',
          DateOfBirth: doc.data().DateOfBirth || '',
          Gender: doc.data().Gender || '',
          WhatsAppNo: doc.data().WhatsAppNo || '',
          UserCountryCode: doc.data().UserCountryCode || '',
          EmergencyContact1: doc.data().EmergencyContact1 || '',
          EmergencyContact1Name: doc.data().EmergencyContact1Name || '',
          EmergencyContact1CountryCode: doc.data().EmergencyContact1CountryCode || '',
          EmergencyContact2: doc.data().EmergencyContact2 || '',
          EmergencyContact2Name: doc.data().EmergencyContact2Name || '',
          EmergencyContact2CountryCode: doc.data().EmergencyContact2CountryCode || '',
          AccountStatus: doc.data().AccountStatus || 'Inactive',
          CreatedOn: doc.data().CreatedOn,
          isProfileComplete: doc.data().isProfileComplete || false,
            //how are you sneh don
        }));

        // Process alerts data
        const alertsData = alertsSnapshot.docs.map((doc) => ({
          id: doc.id,
          TripName: doc.data().TripName || '',
          Location: doc.data().Location || '',
          GroupSize: doc.data().GroupSize || 0,
          UserAlertTimeStamp: doc.data().UserAlertTimeStamp,
          Activity: doc.data().Activity || '',
          State: doc.data().State || '',
          CreatedOn: doc.data().CreatedOn,
          ReturnTimestamp: doc.data().ReturnTimestamp,
          IsTripCompleted: doc.data().IsTripCompleted || false,
          RouteDetails: doc.data().RouteDetails || '',
          BackAndSafeTime: doc.data().BackAndSafeTime,
          NearestTown: doc.data().NearestTown || '',
          UserId: doc.data().UserId || '',
          isVisibleFlag: doc.data().isVisibleFlag !== undefined ? doc.data().isVisibleFlag : true
        }));

        const completeUsers = usersData.filter(user => user.isProfileComplete);
        const incompleteUsers = usersData.filter(user => !user.isProfileComplete);

        setUsersCount(usersSnapshot.size);
        setAlertsCount(alertsSnapshot.size);
        setUsers(usersData);
        setCompleteProfileUsers(completeUsers);
        setIncompleteProfileUsers(incompleteUsers);
        setAlerts(alertsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleString();
  };

  function Dashboard() {
    if (loading) {
      return (
        <div className="container-fluid py-4 text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <div className="dashboard-container">
        {/* Sticky Stats Bar */}
        <div className="sticky-top bg-light py-3 mb-4 shadow-sm">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="stat-icon bg-primary text-white rounded-circle p-3 me-3">
                    <i className="fas fa-users"></i>
                  </div>
                  <div>
                    <h6 className="mb-0">Total Users</h6>
                    <h4 className="mb-0">{usersCount}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="stat-icon bg-success text-white rounded-circle p-3 me-3">
                    <i className="fas fa-check-circle"></i>
                  </div>
                  <div>
                    <h6 className="mb-0">Complete Profiles</h6>
                    <h4 className="mb-0">{completeProfileUsers.length}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="stat-icon bg-warning text-white rounded-circle p-3 me-3">
                    <i className="fas fa-bell"></i>
                  </div>
                  <div>
                    <h6 className="mb-0">Total Alerts</h6>
                    <h4 className="mb-0">{alertsCount}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5 pb-5"> {/* Added bottom margin and padding */}
          {/* Search Section */}
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by User ID..."
                  value={userIdFilter}
                  onChange={(e) => setUserIdFilter(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Alert ID..."
                  value={alertIdFilter}
                  onChange={(e) => setAlertIdFilter(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Navigation Tabs */}
          <ul className="nav nav-tabs mb-4">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'completeUsers' ? 'active' : ''}`}
                onClick={() => setActiveTab('completeUsers')}
              >
                Complete Profiles
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'alerts' ? 'active' : ''}`}
                onClick={() => setActiveTab('alerts')}
              >
                Alerts
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'incompleteUsers' ? 'active' : ''}`}
                onClick={() => setActiveTab('incompleteUsers')}
              >
                Incomplete Profiles ({incompleteProfileUsers.length})
              </button>
            </li>
          </ul>

          {/* Tables */}
          <div className="card">
            <div className="card-body">
              {/* Complete Profile Users Table */}
              {activeTab === 'completeUsers' && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>WhatsApp</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Emergency Contact 1</th>
                        <th>Emergency Contact 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completeProfileUsers
                        .filter(user => user.id.toLowerCase().includes(userIdFilter.toLowerCase()))
                        .map((user) => (
                          <tr key={user.id}>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => copyToClipboard(user.id)}
                                title="Copy ID"
                              >
                                <Copy size={16} />
                              </button>
                            </td>
                            <td>{user.FullName || 'N/A'}</td>
                            <td>{user.Email || 'N/A'}</td>
                            <td>{user.WhatsAppNo ? `${user.UserCountryCode} ${user.WhatsAppNo}` : 'N/A'}</td>
                            <td>{formatTimestamp(user.CreatedOn)}</td>
                            <td>
                              <span className={`badge ${user.AccountStatus === 'Active' ? 'bg-success' : 'bg-secondary'}`}>
                                {user.AccountStatus}
                              </span>
                            </td>
                            <td>
                              {user.EmergencyContact1Name ? (
                                <>
                                  <div>{user.EmergencyContact1Name}</div>
                                  <small>{user.EmergencyContact1CountryCode} {user.EmergencyContact1}</small>
                                </>
                              ) : 'N/A'}
                            </td>
                            <td>
                              {user.EmergencyContact2Name ? (
                                <>
                                  <div>{user.EmergencyContact2Name}</div>
                                  <small>{user.EmergencyContact2CountryCode} {user.EmergencyContact2}</small>
                                </>
                              ) : 'N/A'}
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Incomplete Profile Users Table */}
              {activeTab === 'incompleteUsers' && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incompleteProfileUsers
                        .filter(user => user.id.toLowerCase().includes(userIdFilter.toLowerCase()))
                        .map((user) => (
                          <tr key={user.id}>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => copyToClipboard(user.id)}
                                title="Copy ID"
                              >
                                <Copy size={16} />
                              </button>
                            </td>
                            <td>{user.FullName || 'N/A'}</td>
                            <td>{user.Email || 'N/A'}</td>
                            <td>
                              <span className="badge bg-warning">Incomplete</span>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Alerts Table */}
              {activeTab === 'alerts' && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Trip Name</th>
                        <th>Created On</th>
                        <th>Return Time</th>
                        <th>Status</th>
                        <th>Activity</th>
                        <th>Location</th>
                        <th>Route Details</th>
                        <th>User ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alerts
                        .filter(alert => 
                          (alert.id.toLowerCase().includes(alertIdFilter.toLowerCase()) ||
                          alert.UserId.toLowerCase().includes(userIdFilter.toLowerCase())) &&
                          (userIdFilter === "" || alert.UserId.toLowerCase().includes(userIdFilter.toLowerCase()))
                        )
                        .map((alert) => (
                          <tr key={alert.id}>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => copyToClipboard(alert.id)}
                                title="Copy Alert ID"
                              >
                                <Copy size={16} />
                              </button>
                            </td>
                            <td>{alert.TripName || 'N/A'}</td>
                            <td>{formatTimestamp(alert.CreatedOn)}</td>
                            <td>{formatTimestamp(alert.ReturnTimestamp)}</td>
                            <td>
                              <span className={`badge ${alert.IsTripCompleted ? 'bg-success' : 'bg-warning'}`}>
                                {alert.IsTripCompleted ? 'Completed' : 'In Progress'}
                              </span>
                            </td>
                            <td>{alert.Activity || 'N/A'}</td>
                            {/* ToDo : remove NearestTown and State as only Location is there */}
                            <td>{alert.NearestTown ? `${alert.NearestTown}, ${alert.State}` : alert.Location }</td>
                            <td>
                              <div style={{ maxWidth: '200px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                {alert.RouteDetails || 'N/A'}
                              </div>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => copyToClipboard(alert.UserId)}
                                title="Copy User ID"
                              >
                                <Copy size={16} />
                              </button>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dashboard />
  );
}

export default App;