import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmBackSafePopup, DeleteAlertPopup } from './AlertPopups';
import { faUser, faClock, faTrash, faExclamation, faCheck, faPen, faShield } from '@fortawesome/free-solid-svg-icons';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, deleteDoc, updateDoc, orderBy } from 'firebase/firestore';
import ClipLoader from "react-spinners/ClipLoader";
import AlertIcons from './AlertIcon';

function Alerts() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['userId']);
  const [isProfileComplete, setIsProfileComplete] = useState(null); 
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [alertId, setalertId] = useState('');
  const [userName, setUsername] = useState('')
  const [activeAlert, setactiveAlert] = useState([]);
  const [alertActive, setAlertActive] = useState(false);
  const [disableCreatAlertButton, setdisableCreatAlertButton] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const [alertsentforpopup, setalertsentforpopup] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  
 const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app); 


  const location = useLocation();

useEffect(() => {
    if (location.state?.newAlert) {
        // Clear the navigation state
        navigate(location.pathname, { replace: true });

    }
}, [location, navigate]);

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("userId");
    const userIdFromCookies = cookies.userId;
    const userId = userIdFromLocalStorage || userIdFromCookies; // Use userId from either storage
    if (userId) {
      setUserId(userId);

      const checkProfileCompletion = async () => {
        setLoading(true); // Set loader to true while fetching data
        try {
          const isComplete = await getProfileCompletionStatus(userId); // Fetch profile status
          if (isComplete === null || isComplete === false) {
            navigate("../ProfileForm"); // Navigate to ProfileForm if profile is not complete
          } else {
            setIsProfileComplete(true);

            // Fetch alert data if profile is complete
            const fetchData = async () => {
              try {
                const q = query(
                  collection(db, "AlertTable"),
                  where("UserId", "==", userId),
                  orderBy("CreatedOn", "desc")
                );
                const querySnapshot = await getDocs(q);
                const dataList = [];
                querySnapshot.forEach((doc) => {
                  dataList.push({ id: doc.id, ...doc.data() });
                });
                if (dataList.length > 0) {
                  setAlertActive(true);
                  setactiveAlert(dataList);
                }
              } catch (error) {
                console.error("Error fetching documents:", error);
              } finally {
                setLoading(false); // Set loader to false after fetching data
              }
            };
            fetchData();
          }
        } catch (error) {
          console.error("Error checking profile completion:", error);
          navigate("/ProfileForm"); // Navigate to ProfileForm in case of error
          setLoading(false); // Set loader to false if there's an error
        }
      };

      checkProfileCompletion();
    } else {
      navigate("/login"); // Redirect to login if userId doesn't exist
      setLoading(false); // Set loader to false if user is not logged in
    }
  }, [cookies, navigate]); // Ensure cookies and navigate are included in dependencies

  const getProfileCompletionStatus = async (userId) => {
    try {
      const docRef = doc(db, "UserTable", userId);
      const docSnap = await getDoc(docRef);
      const data2 = docSnap.data();
      setUsername(data2.FullName);
      // Check for admin status
      setIsAdmin(data2.isAdmin === true);
      if (data2.isProfileComplete === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching profile completion status:", error);
      return false;
    }
  };

  const handleCreateAlert = () => {
    navigate("../CreateAlertForm"); // Navigate to CreateAlertForm on button click
  };

  const handleProfileClick = () => {
    navigate("../ProfileForm");
  };

  const handleDeleteAlert = async (alertId) => {
    setLoading(true); // Set loader to true while updating an alert
    try {
      // Get a reference to the document you want to update
      const alertRef = doc(db, "AlertTable", alertId);

      // Update the document by setting 'isVisibleFlag' to false
      await updateDoc(alertRef, { isVisibleFlag: false });

      // Optionally update the state with the new alert data
      setactiveAlert(activeAlert.map((alert) =>
        alert.id === alertId ? { ...alert, isVisibleFlag: false } : alert
      ));
    } catch (error) {
      console.error("Error updating alert:", error);
    } finally {
      setLoading(false); // Set loader to false after updating an alert
    }
  };


  const handleMarkBackSafe = async (alertId) => {
    setLoading(true); // Set loader to true while marking alert as back and safe
    try {
      await updateDoc(doc(db, "AlertTable", alertId), {
        IsTripCompleted: true,
        BackAndSafeTime: new Date(),
      });
      window.location.reload();
    } catch (error) {
      console.error("Error updating alert:", error);
    } finally {
      setLoading(false); // Set loader to false after marking alert as back and safe
    }
  };
  return (
    <div className={loading ? 'overflow-hidden' : ''} style={{ position: 'relative', height: '100vh', backgroundColor: '#f0f1f3', overflowY: loading ? 'hidden' : 'auto' ,overflowX:'hidden'}}>
      <div
        className="container-fluid d-flex flex-column"
        style={{
          maxWidth: '480px',
          backgroundColor: '#f0f1f3',
          height: '100%',
          padding: '0.1rem' // Adjust this value as needed
        }}
      >
        <header className="row text-black py-2 mb-0">
          <div className="col d-flex justify-content-center align-items-center">
            <h1
              className="mb-0"
              style={{
                fontSize: '2rem',
                fontWeight: 'bolder',
                fontStyle: 'italic',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif' // Change to your preferred font
              }}
            >
              alerts
            </h1>
          </div>
        </header>

        <main className="row flex-grow-1 p-3" style={{ marginTop: '-10px' }}>
          <div className="col d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="h5 mb-0" style={{ fontWeight: 400 }}>
                Hi,<b> {userName}!</b>
              </h3>
              <div>
                <a
                  href="#"
                  className="btn p-0 mb-0 me-2" // Added margin to separate buttons
                  onClick={handleProfileClick}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'normal',
                    width: '100px',
                    padding: '5px 10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'blue',
                    backgroundColor: 'transparent',
                  }}
                >
                  Profile
                  <FontAwesomeIcon icon={faPen} style={{ color: 'blue', marginRight: '5px', marginLeft: '10px' }} />
                </a>
                {isAdmin && ( // Only show Dashboard button for admin users
                <a
                  href="#"
                  className="btn p-0 mb-0"
                  onClick={() => navigate("../Dashboard")} // Navigate to Dashboard
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'normal',
                    width: '100px',
                    padding: '5px 10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'blue',
                    backgroundColor: 'transparent',
                  }}
                >
                  Dashboard
                  <FontAwesomeIcon icon={faUser} style={{ color: 'blue', marginRight: '5px', marginLeft: '10px' }} />
                </a>
                )}
              </div>
            </div>
            <div>
              <button
                className="btn btn-primary btn-lg text-uppercase fw-bold"
                onClick={handleCreateAlert}
                disabled={disableCreatAlertButton}
                style={{ backgroundColor: '#111827', borderColor: '#0000FF' }}
              >
                Create new alert
              </button>
              <hr style={{ border: '1px solid black' }} />
            </div>

            {alertActive && (
              <>
                <h3 className="h6 text-uppercase mb-3">MY ALERTS</h3>
                {activeAlert.filter(alert => alert.isVisibleFlag !== false).length > 0 ? (
                  activeAlert.filter(alert => alert.isVisibleFlag !== false).map((alert) => {
                    const {
                      id,
                      TripName,
                      Location,
                      NearestTown,
                      State,
                      IsTripCompleted,
                      isVisibleFlag,
                      AlertedTimestamp,
                      BackAndSafeTime,
                      ReturnTimestamp,
                      RouteDetails,
                      isAlertSent,
                    } = alert;
                    const iconType = isAlertSent
                      ? IsTripCompleted
                        ? 'shield'
                        : 'exclamation'
                      : IsTripCompleted
                        ? 'shield'
                        : 'clock';
                    const returnTime = new Date(ReturnTimestamp.seconds * 1000)
                      .toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      })
                      .replace(/(\w{3})/, (month) => month.toUpperCase()) + ' • ' +
                      new Date(ReturnTimestamp.seconds * 1000)
                        .toLocaleTimeString('en-GB', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true
                        }).replace('am', 'AM').replace('pm', 'PM');

                    var backandsafetime;
                    if (BackAndSafeTime !== undefined && BackAndSafeTime !== null) {
                      backandsafetime = new Date(BackAndSafeTime.seconds * 1000)
                        .toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })
                        .replace(/(\w{3})/, (month) => month.toUpperCase()) + ' • ' +
                        new Date(BackAndSafeTime.seconds * 1000)
                          .toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM');
                    }

                    var alertSentTimeStamp;
                    if (AlertedTimestamp !== undefined && AlertedTimestamp !== null) {
                      alertSentTimeStamp = new Date(AlertedTimestamp.seconds * 1000)
                        .toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })
                        .replace(/(\w{3})/, (month) => month.toUpperCase()) + ' • ' +
                        new Date(AlertedTimestamp.seconds * 1000)
                          .toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                          }).replace('am', 'AM').replace('pm', 'PM');
                    }

                    return (
                      <div key={id} className="card mb-3">
                        <div className="card-body">
                          <div className='mt-2 mb-2'>
                            <div
                              className="d-inline-flex align-items-center mb-3 me-3"
                              style={{
                                borderRadius: '5px',
                                backgroundColor:
                                  isAlertSent && !IsTripCompleted
                                    ? 'red' // Corresponds to 'bg-danger'
                                    : !isAlertSent && !IsTripCompleted
                                      ? '#fde047' // Corresponds to 'bg-warning'
                                      : IsTripCompleted
                                        ? 'green' // Corresponds to 'bg-success'
                                        : '',
                                padding: '8px 12px', // Adjusted padding
                                marginLeft: '-0.5px',
                              }}
                            >
                              <AlertIcons
                                iconType={iconType}
                                className={'pl-1'}
                                style={{
                                  color: !isAlertSent && !IsTripCompleted ? 'black' : 'white',
                                  marginRight: '9px', // Space between icon and text
                                  fontSize: '1rem', // Adjust if needed to match text size
                                  display: 'flex', // Helps with alignment
                                  alignItems: 'center',
                                  padding: '0px 5px'
                                }}
                              />
                              <b
                                style={{
                                  color:
                                    !isAlertSent && !IsTripCompleted
                                      ? 'black'
                                      : 'white',
                                  fontSize: '1rem',
                                  marginLeft: 3, // Remove default margin
                                  lineHeight: 1, // Adjust as needed
                                }}
                              >
                                {isAlertSent
                                  ? IsTripCompleted
                                    ? 'Back & Safe'
                                    : 'ALERT TRIGGERED'
                                  : IsTripCompleted
                                    ? 'Back & Safe'
                                    : 'ALERT IS SET'}
                              </b>
                            </div>
                          </div>

                          <h3 className="card-title">{TripName}</h3>
                          {/* ToDo : remove NearestTown and State as only Location is there */}
                          <p style={{ fontSize: '16px', fontWeight: '600' }}> {NearestTown && State ? `${NearestTown}, ${State}` : Location} </p>
                          <p className="card-text" style={{ fontSize: '16px', fontWeight: '600' }}>
                            <span>{RouteDetails}</span>
                          </p>
                          <div className="time-info-container" style={{ margin: '20px 0' }}>
                            <div className="time-info-row d-flex justify-content-between align-items-center mb-2">
                              <div className="time-info-label" style={{ width: '50%', minWidth: '90px' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>RETURN TIME</strong>
                              </div>
                              <div className="time-info-value" style={{ width: '50%', textAlign: 'right' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
                                  {returnTime || <span>-</span>}
                                </strong>
                              </div>
                            </div>
                            <div className="time-info-row d-flex justify-content-between align-items-center mb-2">
                              <div className="time-info-label" style={{ width: '50%', minWidth: '90px' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>ALERT SENT</strong>
                              </div>
                              <div className="time-info-value" style={{ width: '50%', textAlign: 'right' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
                                  {alertSentTimeStamp || <span>-</span>}
                                </strong>
                              </div>
                            </div>
                            <div className="time-info-row d-flex justify-content-between align-items-center mb-2">
                              <div className="time-info-label" style={{ width: '50%', minWidth: '90px' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>BACK & SAFE</strong>
                              </div>
                              <div className="time-info-value" style={{ width: '50%', textAlign: 'right' }}>
                                <strong className="text-muted" style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
                                  {backandsafetime || <span>-</span>}
                                </strong>
                              </div>
                            </div>
                          </div>
                          {!IsTripCompleted && (
                            <div
                              className="d-flex justify-content-between"
                              style={{ height: "55px", marginBottom: "0px" }}
                            >
                              {!isAlertSent && (
                                <button
                                  aria-label="Delete alert"
                                  onClick={() => {
                                    setSelectedAlertId(id);
                                    setShowDeletePopup(true);
                                  }}
                                  style={{
                                    flexBasis: "18%", // Smaller button
                                    color: "#111827", // Text color
                                    border: "2px solid #111827", // Border color
                                    borderRadius: "0.5rem", // More rounded corners
                                    borderWidth: "2px",
                                    marginRight: "7px", // Border width
                                    appearance: "none", // Remove default browser styles
                                    boxShadow: "none", // Remove any shadow
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              )}
                              <button
                                aria-label="Mark as back and safe"
                                onClick={() => {
                                  setSelectedAlertId(id);
                                  setalertsentforpopup(isAlertSent);
                                  setShowConfirmPopup(true);
                                }}
                                style={{
                                  flexBasis: isAlertSent ? "100%" : "82%", // Full width if delete button is hidden
                                  backgroundColor: "#111827", // Button color
                                  color: "#ffffff", // Text color
                                  padding: "0.75rem 1.25rem", // Larger button size
                                  fontSize: "1rem", // Larger font size
                                  borderRadius: "0.6rem", // More rounded corners
                                  border: "2px solid #111827", // Border color
                                  appearance: "none", // Remove default browser styles
                                  boxShadow: "none", // Remove any shadow
                                  display: "flex", // Flexbox for centering content
                                  alignItems: "center", // Center text vertically
                                  justifyContent: "center", // Center text horizontally
                                  textAlign: "center", // Center text inside button
                                }}
                              >
                                {isAlertSent ? "I'M LATE BUT BACK & SAFE" : "I'M BACK & SAFE"}
                              </button>
                            </div>
                          )}
                        </div>

                        {!IsTripCompleted && !isAlertSent && (
                          <div className="alert text-left" style={{ marginTop: '-20px', marginLeft: '0px' }}>
                            <small>
                              If you don't respond within <strong>60 mins</strong> of Return Time, your contacts will be alerted of a potential emergency situation.
                            </small>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No active alerts</p>
                )}
              </>
            )}

            {!alertActive && (
              <p className="text-center text-muted my-4">
                You have no active alerts.
              </p>
            )}
          </div>
        </main>
        {/* Render ConfirmBackSafePopup and DeleteAlertPopup outside the map to avoid re-renders */}
        {showConfirmPopup && (
          <ConfirmBackSafePopup
            isAlertSent={alertsentforpopup} // or pass the correct value based on logic
            onConfirm={() => {
              handleMarkBackSafe(selectedAlertId);
              setShowConfirmPopup(false);
            }}
            onCancel={() => setShowConfirmPopup(false)}
          />
        )}
        {showDeletePopup && (
          <DeleteAlertPopup
            onConfirm={() => {
              handleDeleteAlert(selectedAlertId);
              setShowDeletePopup(false);
            }}
            onCancel={() => setShowDeletePopup(false)}
          />
        )}
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(8px)',
            zIndex: 9999,
          }}
        >
          <ClipLoader color={"#111827"} loading={loading} size={100} borderWidth={800} />
        </div>
      )}
    </div>
  );

}

export default Alerts;